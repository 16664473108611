<template>
  <div>
    <AddHeaderTitle 
    :Header="Header" 
    />
    <AppHeaderInfoAdminList 
    :infos="infos" 
    />
    <b-card ref="formContainer">
      <b-row align-h='end' >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="mb-2 mr-2"
        @click="openModal" 
        size="sm"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Créer un admin</span>
      </b-button>
      </b-row>
      <b-table
      :fields="fields"
      :items="admins"
      :small="small"
      >
        <!-- Column: User -->
        <template #cell(admin)="admin">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                :text="getAvatar(admin.item.firstname)"
                variant="light-primary"
                size="32"
              />
            </template>
            <b-link
              :to="{ name: 'app-admin-detail', params: { id: admin.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ admin.item.firstname }} {{ admin.item.lastname }}
            </b-link>
            <small class="text-muted"><feather-icon icon="TagIcon" class="mr-25"/>{{ admin.item.nickname }}</small>        
          </b-media>
        </template>
        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${getStatus(data.item.status)}`"
            class="text-capitalize"
          >
            {{ getStatusText(data.item.status) }}
          </b-badge>
        </template>
        <!-- Column: inscription -->
        <template #cell(isComplete)="data">
          <b-row align-h=center>
            <b-col>
              <feather-icon
              :icon="getIcon(data.item.isComplete)"
              size="21"
              class="ml-3"
              :class="`text-${getColor(data.item.isComplete)}`"
              v-b-tooltip.hover.right="getText(data.item.isComplete)"
              />
            </b-col>
          </b-row>
        </template>
        <!-- Column: date connection -->
        <template #cell(lastConnection)="data">
          <div>
             {{ getDate(data.item.lastConnection)  }}
          </div>  
        </template>
        <!-- Column: Actions -->
				<template #cell(actions)="data">
					<b-row>
            <b-button 
            :to="{ name: 'app-admin-detail', params: { id: data.item.id } }"
            variant="flat-primary" size="sm">
              <feather-icon icon="EditIcon" />
              Editer
            </b-button>
            <b-button 
            @click="deleteAdminByKey(data.item.id, data.item.firstname )"
            variant="flat-danger" size="sm">
              <feather-icon icon="Trash2Icon" />
              Supprimer
            </b-button>
					</b-row>
				</template>
      </b-table>
    </b-card>
     <!-- modal : Add new admin -->
    <b-modal
      v-model="modal"
      title="Créer un nouvel Admin"
      ok-title="Ajouter"
      cancel-variant="outline-success"
      ok-variant="success"
      cancel-title="Annuler"
			centered
			@ok="AddnewAdmin(newAdmin)"
      @hidden="hide"
    >
      <b-form>
        <b-col cols="12">
          <b-form-group
            label="Nom"
            label-for="vi-first-name"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="UserIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-first-name"
                placeholder="Nom"
                v-model="newAdmin.firstname"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

         <b-col cols="12">
          <b-form-group
            label="Prénom"
            label-for="vi-first-name"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="UserIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-first-name"
                placeholder="Prénom"
                v-model="newAdmin.lastname"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col cols="12">
          <b-form-group
            label="Email"
            label-for="vi-email"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-email"
                type="email"
                placeholder="Email"
                v-model="newAdmin.email"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- password -->
        <b-col cols="12">
          <b-form-group
            label="Mot de passe"
            label-for="vi-password"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="LockIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-password"
                type="password"
                placeholder="Mot de passe"
                v-model="newAdmin.password"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { BTable, BCard, BRow, BButton, BLink, BMedia,BBadge,VBTooltip,BCol, BAvatar,BModal, BForm, BFormInput, BFormGroup,BInputGroup, BInputGroupPrepend,} from 'bootstrap-vue';
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import Ripple from 'vue-ripple-directive'
import AddHeaderTitle from '@/app/views/components/AppHeaderTitle.vue'
import moment from 'moment'
import  { Admin } from '@/mixins/admin.js'

export default {
  name:"AdminList",
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    AppHeaderInfoAdminList,
    BTable,
    BCard,
    AddHeaderTitle,
    BRow, 
    BButton,
    BLink, BMedia,
    BBadge,
    BCol,
    BAvatar,
    BModal, BForm, BFormInput, BFormGroup,
    BInputGroup, BInputGroupPrepend,
  },
  data() {
    return {
      admins:[],
      fields : [
        { key: 'admin', label: 'ADMIN' },
        { key: 'email', label: 'EMAIL' },
        { key: 'status', label: 'STATUT' },
        { key: 'isComplete', label: 'ETAT INSCRIPTION' },
        { key: 'lastConnection', label: 'DERNIERE CONNEXION' },
        'ACTIONS'
      ], 
      Header: {
			config: "Admin",
			listLink: "",
			list: "Admin-liste",
			},
			infos: {
				title: "Liste des administrateurs",
				content: "texte explicatif de la page",
			},
      small: true,
      statusArray: [{
        status: "1",
        badge : "warning",
        text: "Actif"
      },
      {
        status: "0",
        badge : "danger",
        text: "Inactif"
      }
      ],
      signInArray: [{
        status: true,
        icon : "CheckIcon",
        color: "primary",
        text: "Complet"
      },
      {
        status: false,
        icon : "XIcon",
        color: "danger",
        text: "Incomplet"
      }
      ],
      modal: false,
      selected: 'USA',
      option: ['USA', 'Canada', 'Maxico'],
      newAdmin: {}
    }
  },

  mixins:[Admin],
   mounted() {
    this.loadAdminsList()
  },
  methods: {
    async loadAdminsList() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
        });
      try {
        const response = await this.getAllAdmin()
        if (response.status == 1) {
          this.admins = response.datas
        } else {
          this.$sweetError("AF-52")
        }
        loader.hide()
      } catch {
        loader.hide()
        this.$sweetError("AF-52")
      }
    },
    async AddnewAdmin(newAdmin) {
      this.modal = false
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
        });
      try {
        const response = await this.createAdmin(newAdmin)
        if (response.status == 1) {
          this.$sweetNotif();
          this.loadAdminsList();
          this.newAdmin = {};
        } else {
          this.$sweetError("AF-54")
        }
        loader.hide()
      } catch {
        loader.hide()
        this.$sweetError("AF-54")
      }
    },
    async deleteAdminByKey(id, firstname) {
			if (
          await this.$sweetConfirmation({
					value: firstname
          })
      ) {
				let loader = this.$loading.show({
					container: this.$refs.formContainer,
					canCancel: true,
				});
				try {
					const response = await this.deleteAdmin(id);
					if (response.status === 1 ) {
						this.$sweetNotif("Suppression réussie");	
					this.loadAdminsList();
					} else {
						this.$sweetError("AF-50");
					}
					loader.hide();
					} catch {
					loader.hide();
					this.$sweetError("AF-50");
				}
			}
		},
    getStatus(status) {
      const stat = this.statusArray.find(element => element.status == status);
      return stat.badge 
    },
    getStatusText(status) {
      const stat = this.statusArray.find(element => element.status == status);
      return stat.text 
    },
    getIcon(status) {
      const stat = this.signInArray.find(element => element.status == status);
      return stat.icon 
    },
     getColor(status) {
      const stat = this.signInArray.find(element => element.status == status);
      return stat.color 
    },
    getText(status) {
      const stat = this.signInArray.find(element => element.status == status);
      return stat.text 
    },
    getDate(date) {
      //return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      moment.locale('fr');
      return moment(date).startOf('day').fromNow()
    },
    getAvatar(firstname) {
      const init = firstname.substr(0,2)
      return init
    },
    openModal() {
      this.modal = true
    },
    hide() {
      this.modal = false
    },
  },
}
</script>
